import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
const CommonFeature = ({ data }) => {
  const {
    title,
    discription,
    image,
    commonfeatures,
  } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <SEO
        seo={{
          title: title,
          description: discription,
          previewURL: "https://merlinpanel.com/images/features/preview.png",
        }}
      />
      <div className="max-w-6xl mx-auto mt-20 py-12 px-5 md:px-8" id="feature">
        <div className="flex flex-col items-center w-full mt-12">
          <div className="w-2/3 flex justify-center">
            <img alt={`${title}-preview`} src={image.publicURL}></img>
          </div>

          <h3 className="text-5xl text-blackish mt-10 font-bold">{title}</h3>
          <p className="text-lg text-textcolor mt-6 w-3/4 text-center">
            {discription}
          </p>
        </div>
        <div className=" flex justify-between flex-wrap py-4 md:py-24">
          {commonfeatures.map((val) => {
            return (
              <div className="w-full md:w-5/12 px-8 py-12 my-4 feature-card ">
                <div>
                  <div className="flex my-6 items-center">
                    <div className="w-1/4">
                      <div
                        className={`bg-theme rounded-full flex items-center justify-center icon-holder`}
                      >
                        <img src={val.icon.publicURL} alt="icon" />
                      </div>
                    </div>
                    <div className="w-3/4">
                      <h2 className="inline-block my-1 mx-5 text-xl md:text-2xl font-bold">
                        {val.title}
                      </h2>
                    </div>
                  </div>
                  <p className="text-md text-textcolor text-left">
                    {val.discription}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        id
        title
        discription
        path
        image {
          publicURL
        }
        commonfeatures {
          title
          discription
          icon {
            publicURL
          }
        }
      }
    }
  }
`;

export default CommonFeature;
